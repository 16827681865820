
  import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue';
  import { useToast } from "vue-toastification";

  import ActivityForm from "./components/ActivityForm.vue";
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { IActivityForm, IActivity, ICategory, ICategoryRes, IOption, TIndexedObject } from '@/types';
  import { vuex } from "@/store";
  import { router, routesNames } from '@/router';
  import { categoriesService, activitiesService } from "@/services";
  import { CATEGORIES } from '@/views/admin/activities/api-params';

  export default defineComponent({
    name: 'AddActivity',

    components: { ActivityForm, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const state = reactive({
        categoriesList: [] as ICategory[]
      });

      const activityForm = reactive<IActivityForm>({
        name: '',
        summary: '',
        description: '',
        startDate: '',
        endDate: '',
        link: '',
        type: {} as IOption,
        category: {} as ICategory
      });
      const disabledHandleChanges = ref<boolean>(false);

      const activitiesList = [] as any;

      const typesList = [
        { value: 'child', label: 'Child' },
        { value: 'adult', label: 'Adult' }
      ] as TIndexedObject[];

      const {
        categoriesList,
      } = toRefs(state);

      async function onCreate(payload: IActivity) {
        vuex.setLoading(true);
        await activitiesService.createActivity(payload)
          .then(() => {
            disabledHandleChanges.value = true;
            toast.success('Activity has been created');
            router.push({ name: routesNames.adminActivities });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchCategories() {
        vuex.setLoading(true);
        await categoriesService.fetchCategories(CATEGORIES)
          .then((res: ICategoryRes) => { categoriesList.value = res.data; })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(() => { fetchCategories(); });

      return {
        activitiesList,
        typesList,
        categoriesList,
        activityForm,
        disabledHandleChanges,

        onCreate
      };
    }

  });
